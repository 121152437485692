import { FormFieldProps } from "@components/form/fields/FormFieldProps";
import { useController } from "react-hook-form";

import {
  FieldItem,
  ITextFieldProps,
  ITextFieldStyleProps,
  mergeFuncStyles,
  TextField,
  FieldItemStyleProps,
  IStyleFunctionOrObject,
  FieldItemStyles
} from "@bps/fluent-ui";

import { getLabelStyles } from "../form.utils";

type FormTextFieldProps = FormFieldProps &
  Omit<ITextFieldProps, "name" | "value" | "defaultValue"> & {
    numeric?: boolean;
    max?: number;
    fieldItemStyles?: IStyleFunctionOrObject<
      FieldItemStyleProps,
      FieldItemStyles
    >;
  };

export const FormTextField = (props: FormTextFieldProps) => {
  const {
    name,
    disabled,
    rules,
    numeric,
    max,
    required,
    label,
    fieldItemStyles,
    onChange: customOnChange,
    ...textFieldProps
  } = props;

  const {
    field: { value, onChange, ref, ...fieldProps },
    fieldState: { error },
    formState
  } = useController({ name, rules });

  const handleChange = (e, newValue?: string) => {
    if (!(newValue && numeric)) {
      customOnChange?.(e);
      return onChange(e);
    }

    if (!/^\d*$/.test(newValue)) return;

    const intValue = parseInt(newValue);
    if (max && intValue > max) return;

    customOnChange?.(e);
    onChange(intValue);
  };

  const newStyles = (props: ITextFieldStyleProps) => ({
    subComponentStyles: {
      label: getLabelStyles({ active: props.focused, error })
    }
  });

  const isDisabled = disabled || (formState.isSubmitting && !formState.errors);
  return (
    <FieldItem
      name={name}
      required={required}
      disabled={isDisabled}
      label={label}
      errorMessage={error?.message}
      styles={fieldItemStyles}
    >
      <TextField
        {...textFieldProps}
        {...fieldProps}
        value={value}
        styles={mergeFuncStyles(newStyles, textFieldProps.styles)}
        onChange={handleChange}
        disabled={isDisabled}
      />
    </FieldItem>
  );
};
