import React, { useMemo } from "react";
import {
  useForm,
  UseFormReturn,
  useFormState,
  useWatch
} from "react-hook-form";

import {
  DefaultButton,
  IStackStyles,
  mergeStyleSets,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack
} from "@bps/fluent-ui";

export interface SubmitFormButtonsProps<FormValues> {
  cancelButtonText?: string;
  submitButtonText?: string;
  hideSubmitButton?: boolean;
  allowSubmitOnClean?: boolean;
  showCancelButton?: boolean;
  onCancel?: (form: UseFormReturn<FormValues>) => void;
  buttonsRootStyles?: IStackStyles;
  hideError?: boolean;
  overrideDisablePrimaryButton?: ((values: FormValues) => boolean) | boolean;
  submitButtonId?: string;
}

export const SubmitFormButtons = <FormValues extends object = object>({
  submitButtonText = "Save",
  cancelButtonText = "Cancel",
  hideSubmitButton,
  showCancelButton,
  onCancel,
  buttonsRootStyles,
  allowSubmitOnClean,
  overrideDisablePrimaryButton,
  submitButtonId
}: SubmitFormButtonsProps<FormValues>) => {
  const form = useForm<FormValues>();

  const formState = useFormState<FormValues>();
  const values = useWatch<FormValues>();

  const disabled = useMemo(() => {
    if (typeof overrideDisablePrimaryButton === "undefined") {
      return (
        (!allowSubmitOnClean && !formState.isDirty) || formState.isSubmitting
      );
    }

    if (typeof overrideDisablePrimaryButton === "function")
      return overrideDisablePrimaryButton(values as FormValues);
    return overrideDisablePrimaryButton;
  }, [
    allowSubmitOnClean,
    overrideDisablePrimaryButton,
    formState.isDirty,
    formState.isSubmitting,
    values
  ]);

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Stack
        horizontal
        tokens={{ childrenGap: 8 }}
        styles={mergeStyleSets(
          { root: { alignSelf: "end" } },
          buttonsRootStyles
        )}
      >
        {showCancelButton && (
          <DefaultButton onClick={() => onCancel && onCancel(form)}>
            {cancelButtonText}
          </DefaultButton>
        )}
        {!hideSubmitButton && (
          <PrimaryButton
            id={submitButtonId}
            styles={{ root: { alignSelf: "end" } }}
            disabled={disabled}
            type="submit"
            iconProps={{ iconName: "Save" }}
          >
            {formState.isSubmitting ? (
              <Spinner size={SpinnerSize.small} />
            ) : (
              submitButtonText
            )}
          </PrimaryButton>
        )}
      </Stack>
    </Stack>
  );
};
