import { Controller, useFormContext } from "react-hook-form";

import {
  FieldItem,
  FieldItemProps,
  FieldItemStyles,
  mergeStyleSets,
  SpinNumberInput,
  SpinNumberInputProps
} from "@bps/fluent-ui";
import { FormFieldProps } from "./FormFieldProps";

type FormSpinNumberInputProps = Omit<
  SpinNumberInputProps,
  "value" | "onChange"
> &
  Pick<FieldItemProps, "suffix" | "required" | "label" | "hint"> & {
    fieldItemStyles?: Partial<FieldItemStyles>;
  } & FormFieldProps;

export const FormSpinNumberInput = (props: FormSpinNumberInputProps) => {
  const {
    name,
    disabled,
    rules,
    suffix,
    label,
    fieldItemStyles,
    required,
    hint,
    ...spinNumberInputProps
  } = props;

  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { value, onChange, ref, ...fieldProps },
        fieldState: { error: validationError }
      }) => (
        <FieldItem
          name={name}
          label={label}
          required={required}
          errorMessage={validationError?.message}
          disabled={disabled}
          suffix={suffix}
          hint={hint}
          styles={mergeStyleSets(
            {
              itemWrapper: { alignItems: "baseline" }
            },
            fieldItemStyles
          )}
        >
          <SpinNumberInput
            {...spinNumberInputProps}
            onChange={onChange}
            disabled={disabled}
            value={value}
            {...fieldProps}
          />
        </FieldItem>
      )}
    />
  );
};
