import { Controller, useFormContext } from "react-hook-form";

import {
  ButtonsGroupChoice,
  ButtonsGroupChoiceProps,
  FieldItem,
  FieldItemStyles
} from "@bps/fluent-ui";
import { FormFieldProps } from "./FormFieldProps";

type FormButtonsGroupSingleChoiceProps<T> = Omit<
  ButtonsGroupChoiceProps<T>,
  "name" | "value" | "onChange" | "theme"
> &
  FormFieldProps & {
    label?: string;
    hint?: string;
    required?: boolean;
  } & { fieldItemStyles?: Partial<FieldItemStyles> };

export const FormButtonsGroupSingleChoice = (
  props: FormButtonsGroupSingleChoiceProps<any>
) => {
  const {
    name,
    label,
    options,
    rules,
    hint,
    fieldItemStyles,
    required,
    ...buttonGroupSingleChoiceProps
  } = props;

  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { value, onChange, ref, ...fieldProps },
        fieldState: { error: validationError }
      }) => (
        <FieldItem
          name={name}
          label={label}
          hint={hint}
          styles={fieldItemStyles}
          required={required}
          errorMessage={validationError?.message}
        >
          <ButtonsGroupChoice
            options={options}
            value={value}
            onChange={onChange}
            {...buttonGroupSingleChoiceProps}
            {...fieldProps}
          />
        </FieldItem>
      )}
    />
  );
};
