import { FunctionComponent } from "react";
import { FormFieldProps } from "./FormFieldProps";
import { Controller, useFormContext } from "react-hook-form";
import {
  TenantPromotionCodeSelect,
  TenantPromotionCodeSelectProps
} from "../../pickers/TenantPromotionCodeSelect";
import { FieldItem } from "@bps/fluent-ui";

type FormTenantPromotionCodeSelectProps = Omit<
  TenantPromotionCodeSelectProps,
  "selectedKeys" | "onChangeSelectedKeys" | "label"
> &
  FormFieldProps & { label?: string };

export const FormTenantPromotionCodeSelect: FunctionComponent<FormTenantPromotionCodeSelectProps> = ({
  name,
  rules,
  required,
  label,
  disabled,
  ...rest
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { value, onChange, ref, ...fieldProps },
        fieldState: { error: validationError }
      }) => (
        <FieldItem
          name={name}
          required={required}
          label={label}
          disabled={disabled}
          errorMessage={validationError?.message}
        >
          <TenantPromotionCodeSelect
            disabled={disabled}
            selectedKeys={value}
            onChangeSelectedKeys={onChange}
            {...rest}
            {...fieldProps}
          />
        </FieldItem>
      )}
    />
  );
};
