import { CopyToClipboardButton } from "@components/buttons/CopyToClipboardButton";
import { DisplayGuid } from "@components/DisplayGuid";
import { guid } from "@libs/common/guid";

import {
  IStackStyles,
  ITextProps,
  mergeStyleSets,
  Stack,
  Text,
  TextProps,
  useTheme
} from "@bps/fluent-ui";

export interface CopyableTextProps extends ITextProps {
  clipboardContent?: string;
  horizontalFill?: boolean;
  stackStyles?: IStackStyles;
}

export const CopyableText = ({
  clipboardContent,
  horizontalFill,
  children,
  stackStyles,
  ...textProps
}: CopyableTextProps) => {
  const theme = useTheme();
  if (
    !(
      clipboardContent ||
      typeof children === "string" ||
      typeof children === "object"
    )
  ) {
    throw new Error(
      "The `clipboardContent` property must be provided if the children property is not a string"
    );
  }

  const newStackStyles = mergeStyleSets(
    {
      root: {
        display: "inline-flex",
        width: horizontalFill ? "100%" : undefined
      }
    },
    stackStyles
  );

  return children ? (
    <Stack
      horizontal
      verticalAlign="center"
      tokens={{ childrenGap: theme.spacing.s2 }}
      styles={newStackStyles}
    >
      <Text {...textProps}>{children}</Text>
      <CopyToClipboardButton
        clipboardContent={clipboardContent ?? (children as string)}
      />
    </Stack>
  ) : (
    <></>
  );
};

export type CopyableGuidProps = Omit<TextProps, "children"> & {
  short?: boolean;
  value: guid;
};

export const CopyableGuid = ({
  short,
  value,
  ...textProps
}: CopyableGuidProps) => {
  return value ? (
    <CopyableText clipboardContent={value} {...textProps}>
      <DisplayGuid short={short} {...textProps}>
        {value}
      </DisplayGuid>
    </CopyableText>
  ) : (
    <></>
  );
};
